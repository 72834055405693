import './App.css';
import Rotas from './rotas';

function App() {
  return (
    <div className="App">
      <Rotas/>
    </div>
  );
}

export default App;
