function MensagemVisita() {

    return (

        <>


            <div className="visitaDiv">

                <h1 className="visitaH1">Vem visitar a gente!</h1>

                <p className="visitaP">Centro Tecnológico de Inclusão Digital e Social - R. Rosa Cândido, 35 - Lagoa Do Itaenga, Lagoa do Itaenga - PE, 55840-000 <br/> Telefone/Whatsapp:
                (081) 97343-2301 <br/> E-mail: <a
                    href="mailto:administrativo@somosconexaosocial.org">administrativo@somosconexaosocial.org</a>
                </p>


            </div>
                    
        
        
        
        
        
        
        </>
    );
}

export default MensagemVisita;